export async function useTryCatchSuspense<
  F extends (...args: any[]) => any,
  A extends Parameters<F>,
>(queryFn: F, ...args: A) {
  const queryFnResult = queryFn(...args)
  try {
    await queryFnResult.suspense()
  } catch (e) {
    console.error(e)
  }
  return queryFnResult as ReturnType<F>
}

export function useTryCatchSSRSuspense<
  F extends (...args: any[]) => any,
  A extends Parameters<F>,
>(queryFn: F, ...args: A) {
  const queryFnResult = queryFn(...args)

  onServerPrefetch(async () => {
    try {
      await queryFnResult.suspense()
    } catch (e) {
      console.error(e)
    }
  })

  return queryFnResult as ReturnType<F>
}
